var WebFontConfig = {
    custom: {
        families: [ '', '' ],
        urls: [ 'css/fonts.css' ]
    },

    loading: function() {},

    active: function() {},

    inactive: function() {},

    //if any specific font family requirements
    /*fontloading: function(familyName, fvd) {},
    fontactive: function(familyName, fvd) {},
    fontinactive: function(familyName, fvd) {}*/
};

(function() {
    var wf = document.createElement('script');
    wf.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();
